
body {
  margin: 0;
  font-family: 'Lato';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:'lato';
}

body > iframe {
  display: none;
}

/* styles.css */
input[type="checkbox"] {
  width: 10%;
  height: 10%;
  accent-color: #87D3D7;
}

/* TopBar Styles */
.top-bar {
  background: #87D3D7;
  color: #fff;
  padding: 100px; /* Adjust padding to make it thin */
  display: flex;
  flex-direction: column; /* Set the flex direction to column */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center items horizontally */
  height: 80vh; /* Set the height to 100% of the viewport height */
  position: fixed;
  top: 0;
  left: 0;
}

.footer {
  background: #87D3D7;
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.footer-p {
  color: #000000;
  position: fixed;
  bottom: 0;
  left: 35px;
  font-size: 12px;
  
}

.logo-link {
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.logo-img {
  
  height: 25px;
  position: absolute;
  top: 41px;
  left: 8%;  /* Move the left edge to the center */
  
}

.nav-links {
  display: flex;
  flex-direction: column; /* Set the flex direction to column for vertical layout */
  align-items: left; /* Center links horizontally */
  position: fixed;
  
}

.nav-link {
  margin: 15px 0; /* Add margin to separate the vertical links */
  color: #000000;
  text-decoration: none;
  width: 120px;
  font-size: 16px;
  padding-bottom: 5px;
  border-bottom: 2px solid transparent;
}

.nav-link.active {
  border-bottom-color: #000000;
  font-size: 20px;
  font-weight: bold;
}

.nav-link-account.active {
  border-bottom-color: #000000;
  font-size: 20px;
  font-weight: bold;
  
}

.nav-link-account{

  margin: 15px 0; /* Add margin to separate the vertical links */
  position: fixed;
  bottom: 35px;
  color: #000000;
  text-decoration: none;
  width: 120px;
  font-size: 16px;
  padding-bottom: 5px;
  border-bottom: 2px solid transparent;
}


/* Add these styles to your CSS file */
.zoom-image-container {
  position: relative;
  display: inline-block; /* Display the container inline */
}

.thumbnail-image {
  width: 80px; /* Set the width of the thumbnail image */
  height: auto; /* Automatically adjust the height to maintain aspect ratio */
  height: 100px; /* Set the height to 100px */
  object-fit: cover; /* This will ensure the image covers the area, cropping if necessary without distorting the aspect ratio */
  
}

.popup-overlay {
  
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(255, 255, 255, 0.9); /* Popup background color */
  transition: opacity 0.3s, visibility 0.3s;
  z-index: 1; /* Ensure the popup displays on top of other elements */
}

.popup-image {
  position: absolute;
  max-width: 200px; /* Set the maximum width of the popup image */
  max-height: 200px; /* Set the maximum height of the popup image */
  display: block;
  margin: 0 auto; /* Center the popup image horizontally */
}

.zoom-image-container:hover .popup-overlay {
  opacity: 1;
  visibility: visible; /* Show the popup when hovering over the container */
}


/* Accounts page  styling */

.info-form {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 2px solid #87D3D7;
  margin: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Shadow effect */
  height: 620px;
  
}

.orders-info-form {
  display: flex;
  flex-direction: column;
  padding: 10px;
  /* border: 2px solid #87D3D7; */
  margin: 0px;
  height: 45px;
  
}
.form-labels{
  margin: 10px;
  font-weight: 400;
}

.order-form-labels{
  margin: 5px;
  font-weight: 500;
}

.text-box {
  border-radius: 10px; /* Rounded border */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Shadow effect */
  padding: 10px 10px; /* Padding inside the text box */
  border: 1px solid #ccc; /* Border around the text box */
  font-style: italic;
  margin-left: 8px;
  color: #808080;
}

.onboarding-text-box {
  border-radius: 10px; /* Rounded border */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Shadow effect */
  padding: 10px; /* Padding inside the text box */
  border: 1px solid #ccc; /* Border around the text box */
  background-color: #CDFFCD;
  font-style: italic;
  margin-left: 8px;
}

.edit-button {
  background-color: #87D3D7; /* Blue background */
  color: white; /* White text */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Shadow effect */
  border: none; /* No border */
  width: 200px;
  display: block; /* Change display to block */
  margin: 20px auto; /* Auto margins for horizontal centering */
  padding: 7px 1px; /* Padding around text */
  text-align: center; /* Center the text */
  text-decoration: none; /* No underline on text */
  display: inline-block; /* Allow it to sit next to other items */
  font-size: 16px; /* Size of the text */
  cursor: pointer; /* Change mouse pointer on hover */
  border-radius: 10px; /* Rounded corners */
  margin-top: 20px;
}


.logout-button{
  background-color: #87D3D7; /* Blue background */
  color: white; /* White text */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Shadow effect */
  border: none; /* No border */
  padding: 10px 20px; /* Padding around text */
  text-align: center; /* Center the text */
  text-decoration: none; /* No underline on text */
  display: inline-block; /* Allow it to sit next to other items */
  font-size: 16px; /* Size of the text */
  /* margin: 4px 2px; Margin around the button */
  cursor: pointer; /* Change mouse pointer on hover */
  border-radius: 10px; /* Rounded corners */
  margin-top: 10px;
  margin-right: 10px;

}


/* Reports Page CSS */
.report-form {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #F49C7D;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Original shadow effect */
  height: 200px;
  border-radius: 10px; /* Rounded corners */
  cursor: pointer; /* Change mouse pointer on hover */
  background-color: #F49C7D;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for transform and shadow */
}

.future-report-form:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3); /* Enhanced shadow effect on hover */
  transform: scale(1.05); /* Slightly increase size */
}

.future-report-form {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #F49C7D;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Original shadow effect */
  height: 200px;
  border-radius: 10px; /* Rounded corners */
  cursor: pointer; /* Change mouse pointer on hover */
  background-color: #ffffff;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for transform and shadow */
}

.report-form:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3); /* Enhanced shadow effect on hover */
  transform: scale(1.05); /* Slightly increase size */
}



 /* Home Page */
 
/* Style for modal overlay */
.modal-overlay {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  position: fixed; /* Full viewport */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.decline-modal-overlay{
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  position: fixed; /* Full viewport */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.decline-modal-content {
  background-color: #87D3D7; /* Background color */
  width: 30vh;
  height: 30vh;
  padding: 1%;
  border-radius: 10px; /* Rounded corners */
  position: fixed; /* Fixed position */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Align center */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Shadow for 3D effect */
}

/* Style for modal content */
.modal-content {
  background-color: #87D3D7; /* Background color */
  width: 50vh;
  height: 60vh;
  padding: 2%;
  border-radius: 10px; /* Rounded corners */
  position: fixed; /* Fixed position */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Align center */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Shadow for 3D effect */
}

.input-group input,
.input-group select,
.input-group textarea {
  width: 100%; /* Ensures full-width within the container */
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
  margin-top: 5px; /* Adds a little space between the label and the input */
  margin-bottom: 10px; /* Adds some space below the input for better spacing */
}

.input-group textarea {
  resize: vertical; /* Allows the textarea to be resized only vertically */
}


.input-group h3 {
  margin-bottom: 2px; /* Adjust as needed */
}

.input-group p, .input-group input, .input-group select, .input-group textarea {
  /* display: block; Makes sure the element takes up the full width */
  width: 100%; /* Adjust as needed */
  padding: 2px;
}


/* Optionally, you can add styles for buttons, labels, and other elements inside the modal */

.login-container {
  position: relative; /* Added for positioning the pseudo-element */
  
  background-size: cover; /* Cover the entire viewport */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-attachment: fixed; /* Keep the background fixed during scroll */
  margin: 0; /* Remove default margin */
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow: auto; /* Add scroll to the page if needed */
}

.login-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #87D3D7; /* Black with 50% opacity */
  z-index: 1; /* Ensure it's behind the content */
}


.log-in-form{
  display: flex;
  flex-direction: column;
  padding: 30px;
  border: 2px solid #87D3D7;
  width: 400px;
  
  background-color: #fff;
  margin: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Shadow effect */
  height: 400px;
  z-index: 2; /* Ensure it's behind the content */
}

.log-in-button {
  background-color: #87D3D7; /* Blue color */
  color: white;             /* White text */
  border: none;             /* No border */
  padding: 10px 20px;       /* Padding around the text */
  text-align: center;       /* Center the text */
  text-decoration: none;    /* No underline */
  display: inline-block;    /* Align the button correctly */
  font-size: 16px;          /* Set font size */
  margin: 4px 4px;          /* Margin around the button */
  cursor: pointer;          /* Change cursor to pointer on hover */
  border-radius: 10px;       /* Rounded corners */
  
}

.log-in-button:hover {
  background-color: #1e676c; /* Slightly darker blue on hover */
}

.support-container {
  position: fixed;
  top: 35px;
  left: 1385px;
}

.support-btn {
  background-color: #ffffff;
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  border: none;
  border-radius: 50%;
  margin-top: 10.5px;
  margin-right: 10px;
  font-size: 16px;
  width: 35px !important;
  height: 37px;
  cursor: pointer;
  box-shadow: 0px 10px 10px rgba(0.5, 0.5, 0.5, 0.5);

}

.support-btn:hover {
  background-color: #31bac1; /* Light background on hover */
  border-color: #31bac1; /* Change border color on hover */
  color: white; /* Keep text color white on hover */

}


.contact-info {
  position: fixed;
  top: 70px;
  left: 1150px;
  background-color: white;
  border: 1px solid #87D3D7;
  padding: 20px;
  margin-top: 10px;
  border-radius: 8px;
  z-index: 2;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.contact-btn {
  display: flex;
  background-color: #87D3D7;
  color: white;
  text-align: left;
  border: none;
  border-radius: 4px;
  padding: 10px;
  margin: 5px;
  text-decoration: none;
  font-size: 16px;
}

.contact-btn:hover {
  background-color: #31bac1;
}

.flag-resolution-centered {
  position: absolute;
  left: 50%;
  top: 65%;
  transform: translate(-50%, -50%);
  width: 55%;
}

.flag-resolution-result-centered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
}

/* SpotCheck.css */
@keyframes moveCoin {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(var(--move-x), var(--move-y));
  }
}

.coin-animation {
  position: fixed;
  width: 50px;
  height: 50px;
  transition: top 1s ease, left 1s ease;
}

@keyframes spin {
  0% { transform: rotateX(0deg); }
  5% { transform: rotateX(18deg); }  /* Slow start */
  10% { transform: rotateX(72deg); }
  15% { transform: rotateX(162deg); }
  20% { transform: rotateX(288deg); }
  25% { transform: rotateX(450deg); }
  30% { transform: rotateX(648deg); }
  35% { transform: rotateX(882deg); }
  40% { transform: rotateX(1152deg); }
  45% { transform: rotateX(1458deg); }
  50% { transform: rotateX(1800deg); } /* Fastest */
  55% { transform: rotateX(2160deg); }
  60% { transform: rotateX(2520deg); }
  65% { transform: rotateX(2880deg); }
  70% { transform: rotateX(3240deg); }
  75% { transform: rotateX(3420deg); }
  80% { transform: rotateX(3510deg); }
  85% { transform: rotateX(3570deg); }
  90% { transform: rotateX(3600deg); } /* Slow down more */
  95% { transform: rotateX(3600deg); }
  100% { transform: rotateX(3600deg); } /* Stop */
}

.spinning {
  animation: spin 4s ease-in-out;
}

.jackpot-sticker {
  position: absolute;
  top: -35px;
  left: -35px;
  width: 150px;
  height: 120px;
  background-color: #F49C7D; /* Color matching "Redeem Your Coins" */
  clip-path: polygon(
    50% 0%, 
    61% 35%, 
    98% 35%, 
    68% 57%, 
    79% 91%, 
    50% 70%, 
    21% 91%, 
    32% 57%, 
    2% 35%, 
    39% 35%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 14px;
  transform: rotate(-15deg);
  text-align: center;
  z-index: 1; /* Ensure it's on top */
}

@keyframes growAndShrink {
  0% {
    transform: scale(1);
  }
  5% {
    transform: scale(1.12);
  }
  10% {
    transform: scale(1.14);
  }
  15% {
    transform: scale(1.16);
  }
  20% {
    transform: scale(1.18);
  }
  25% {
    transform: scale(1.2);
  }
  30% {
    transform: scale(1.25);
  }
  35% {
    transform: scale(1.28);
  }
  40% {
    transform: scale(1.3);
  }
  45% {
    transform: scale(1.32);
  }
  50% {
    transform: scale(1.34);
  }
  55% {
    transform: scale(1.36);
  }
  60% {
    transform: scale(1.4);
  }
  65% {
    transform: scale(1.6);
  }
  70% {
    transform: scale(1.8);
  }
  75% {
    transform: scale(2);
  }
  80% {
    transform: scale(2.2);
  }
  85% {
    transform: scale(2.4);
  }
  90% {
    transform: scale(2.5);
  }
  95% {
    transform: scale(2.6);
  }
  100% {
    transform: scale(2.7);
  }
}


.coin2-animation {
  position: fixed;
  transition: all 2s ease;
  transform-origin: center;
}

.info-button {
  background-color: #f49c7d; /* Custom color */
  color: white;
  font-size: 13px; /* Adjust size for superscript look */
  border: none;
  cursor: pointer;
  border-radius: 100%; /* Makes the button circular */
  display: inline-flex; /* Keeps the content centered */
  align-items: center;
  justify-content: center;
  height: 16px; /* Set height */
  width: 16px; /* Set width to make it a circle */
  line-height: 20px; /* Center text vertically */
  position: relative; /* Position relative for accurate placement */
  top: -0.5em; /* Adjust to position as superscript */
  padding-left: 6.5px; /* Remove default margin */
  padding-bottom: 3px; /* Remove default margin */
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3; /* Ensure it sits above other content */
}

.popup-inner {
  background-color: #87D3D7;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  text-align: left;
  max-width: 550px;
  width: 90%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border: 2px solid #F49C7D;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: -1px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #F49C7D;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 24px;
  margin-top: 2px;
}

.slider.round:before {
  border-radius: 50%;
}

.progress-container {
  display: flex;
  margin-right: 1px;
  width: 500px;
  margin-right: 30px;
  margin-left: 50px;
}

.progress-bar {
  display: flex;
  width: 300px; /* Adjust based on preference */
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
}

.progress-processed {
  background-color: #f49c7d; /* Match the initial page color */
  width: 0%;
  transition: width 0.3s ease-in-out;
}

.progress-unprocessed {
  background-color: #fad5c0; /* Match the initial page color */
  width: 0%;
  transition: width 0.3s ease-in-out;
}

.progress-text {
  font-weight: bold;
  font-size: 13px;
  margin-left: 5px;
  color : #f49c7d;
  width: 150px;
}


.tooltip {
  position: absolute;
  background-color: white;
  border: none;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  top: -10px;
  left: -110px;
  z-index: 1;
}

.button-style {
  border: 1px solid #31bac1;
  background-color: #31bac1;
  padding: 8px;
  color: white;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.select-box {
  border: 1px solid #87D3D7;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  font-style: normal;
  cursor: pointer;
  font-family: 'Arial'; /* Customize font family */
}

.select-box:focus {
  border-color: #31bac1; /* Change border color on focus */
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2); /* Make shadow a bit stronger for focus effect */
  outline: none; /* Remove default outline */
}

.support-button {
  border: 1px solid #87D3D7;
  border-radius: 8px;
  padding: 10px;
  background-color: #87D3D7; /* Light background on hover */
  font-size: medium;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  font-style: normal;
  cursor: pointer; /* Initial background color */
  border-color: #31bac1; /* Initial border color */
  color: white; /* Initial text color */
  transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}

.support-button:hover {
  cursor: pointer;
  background-color: #31bac1; /* Light background on hover */
  border-color: #31bac1; /* Change border color on hover */
}

.support-button:disabled {
  background-color: #ccc; /* Grey background when disabled */
  border-color: #bbb; /* Grey border when disabled */
  color: #777; /* Grey text when disabled */
}

.support-button-accent {
  border: 1px solid #E4846E;
  border-radius: 8px;
  padding: 10px;
  background-color: #F49C7D; /* Light background on hover */
  font-size: medium;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  font-style: normal;
  cursor: pointer; /* Initial background color */
  border-color: #E4846E; /* Initial border color */
  color: white; /* Initial text color */
  transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}

.support-button-accent:hover {
  cursor: pointer;
  background-color: #E4846E; /* Light background on hover */
  border-color: #E4846E; /* Change border color on hover */
}

.support-button-accent:disabled {
  background-color: #ccc; /* Grey background when disabled */
  border-color: #bbb; /* Grey border when disabled */
  color: #777; /* Grey text when disabled */
  cursor: default
}

.decline-button {
  border: 1px solid #D32F2F; /* Dark red border */
  border-radius: 8px;
  padding: 10px;
  background-color: #EF5350; /* Light red background */
  font-size: medium;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  font-style: normal;
  cursor: pointer;
  color: white; /* Initial text color */
  transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}

.decline-button:hover {
  cursor: pointer;
  background-color: red; /* Darker red background on hover */
  border-color: #D32F2F; /* Darker red border on hover */
  color: white; /* Keep text color white on hover */
}

.decline-button:disabled {
  background-color: #ccc; /* Grey background when disabled */
  border-color: #bbb; /* Grey border when disabled */
  color: #777; /* Grey text when disabled */
  cursor: default
}

.tab-outward {
  background-color: #E0E0E0; /* Slightly darker grey */
  border-bottom-left-radius: -20px;
  border-bottom-right-radius: -20px;
}

.white-modal {
  overflow: auto;
  position: fixed; /* Full viewport */
  width: 50%;
  height: auto;
  max-height: 90vh;
  padding-block-end: 100px;
  margin: auto;
  padding: 0;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(251, 251, 251);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: fixed; /* Fixed position */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Align center */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Shadow for 3D effect */
  z-index: 3;
}

.white-modal-overlay {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  position: fixed; /* Full viewport */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.action-button {
  border: 1px solid #87D3D7;
  border-radius: 8px;
  padding: 8px;
  background-color: #87D3D7; /* Light background on hover */
  font-size: small;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  font-style: normal;
  cursor: pointer; /* Initial background color */
  border-color: #31bac1; /* Initial border color */
  color: white; /* Initial text color */
  transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}

.action-button:hover {
  cursor: pointer;
  background-color: #31bac1; /* Light background on hover */
  border-color: #31bac1; /* Change border color on hover */
}

.action-button:disabled {
  background-color: #ccc; /* Grey background when disabled */
  border-color: #bbb; /* Grey border when disabled */
  color: #777; /* Grey text when disabled */
  cursor: default
}

.action-button-white {
  border: 1px solid #87D3D7;
  border-radius: 8px;
  padding: 8px;
  background-color: white; /* Light background on hover */
  font-size: small;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  font-style: normal;
  cursor: pointer; /* Initial background color */
  border-color: #31bac1; /* Initial border color */
  color: #31bac1; /* Initial text color */
  transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}

.action-button-white:hover {
  cursor: pointer;
background-color: #e0f7f8; /* Lighter background on hover */
  border-color: #87D3D7; /* Change border color to a lighter shade on hover */
}

.action-button-white:disabled {
  background-color: #ccc; /* Grey background when disabled */
  border-color: #bbb; /* Grey border when disabled */
  color: #777; /* Grey text when disabled */
}

.action-button-accent {
  border: 1px solid #E4846E;
  border-radius: 8px;
  padding: 8px;
  background-color: #F49C7D; /* Light background on hover */
  font-size: small;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  font-style: normal;
  cursor: pointer; /* Initial background color */
  border-color: #E4846E; /* Initial border color */
  color: white; /* Initial text color */
  transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}

.action-button-accent:hover {
  cursor: pointer;
  background-color: #E4846E; /* Light background on hover */
  border-color: #E4846E; /* Change border color on hover */
}

.action-button-accent:disabled {
  background-color: #ccc; /* Grey background when disabled */
  border-color: #bbb; /* Grey border when disabled */
  color: #777; /* Grey text when disabled */
}

.scroll-container {
    display: flex;
    text-align: left;
    overflow: hidden; /* Hide overflow */
    gap: 40px;
    padding-left: 10px;
    padding-top: 8px;
    padding-bottom: 16px;
    width: 100%; /* Adjust as needed */
    scrollbar-width: none; /* For Firefox */
}

.scroll-container::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Edge */
}

.basic-card {
    /* flex: 1 1 calc(33% - 40px);              Grow, shrink, and set the base width */

    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    /* width:auto; */
    height: auto;
    /* min-width: 300px;
    max-width: calc(32% - 40px); */
    display: flex;
    flex-direction: column;
    overflow: auto;
    transition: transform 0.3s, box-shadow 0.3s;
  }

.supplier-card {
    flex: 1 1 calc(33% - 40px);              /* Grow, shrink, and set the base width */

    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    /* width:auto; */
    height: auto;
    min-width: 300px;
    max-width: calc(32% - 40px);
    overflow: auto;
    transition: transform 0.3s, box-shadow 0.3s;
  }

.supplier-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.supplier-card.hovered {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}


.table-container {
    border: 2px solid #87D3D7;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 610px; /* Set a fixed height */
    overflow-y: auto; /* Enable vertical scrolling */
    box-shadow: 0 30px 30px rgba(0, 0, 0, 0.1);
}

.table {
    background-color: #ffffff;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    position: relative; /* Ensure positioning context */
}

.table thead {
    position: sticky;
    font-weight: bold; /* Emphasize header text */
    top: 0;
    z-index: 2; /* Higher z-index comes on top */

}

.table th {
    font-size: 13px;
    background-color: #ffffff;
    color: #000000;
    border-bottom: 2px solid black;
    padding: 10px;
    text-align: left; /* Default text alignment for header */
}

.table tbody tr {
    font-size: 13px;
}

.table tbody tr:nth-child(even) {
    background-color: #ebf4f5; /* Alternate row color */
}

/* Hover effect class */
.hover-effect tbody tr:hover {
    background-color: #87D3D7; /* Hover color */
}

.table td {
    padding: 10px;
}

.promo-image {
  width: 150px; /* Set the width of the thumbnail image */
  /* height: auto; Automatically adjust the height to maintain aspect ratio */
  height: 150px; /* Set the height to 100px */
  object-fit: contain; /* This will ensure the image covers the area, cropping if necessary without distorting the aspect ratio */
  
}

.icon-hover {
  color:#87D3D7
}
.icon-hover:hover {
    color: #31bac1; /* Desired hover color */
}

.orange-text {
  color: #C37C64;
  font-size: 18px;
}

/* Reward Image Style */
.reward-image {
    width: 70px;
    height: 70px;
    border-radius: 10px;
}

/* Reward Button Style */
.reward-button {
    background-color: #5E98A0;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 5px;
}

/* Disabled Redeem Button Style */
.reward-button:disabled {
    background-color: #f0f0f0;
    cursor: not-allowed;
    color: #a0a0a0;
}

/* Enabled Button Style */
.enabled-button {
    padding: 12px 22px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    background-color: white;
    font-size: 20px;
    cursor: pointer;
    font-style: italic;
    border: 1px solid #87D3D7;
}

/* Reward Card Style */
.reward-card {
    position: relative;
    border: 1px solid #87D3D7;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
    width: 300px;
    height: 140px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

/* Selected Reward Card Style */
.reward-card.selected {
    transform: scale(1.05);
}

/* Tick Style */
.tick {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    background-color: green;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
}
.icon-hover-accent {
  color: #F49C7D
}
.icon-hover-accent:hover {
    color: #E4846E; /* Desired hover color */
}