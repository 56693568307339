.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f7f7; /* Light grey background */
}

.login-form {
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background: white; /* White background for the form */
    display: flex;
    flex-direction: column;
    gap: 20px; /* Spacing between elements */
}

.input-field {
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #ddd; /* Light grey border */
    font-size: 16px;
}

.login-button {
    padding: 15px;
    border-radius: 5px;
    border: none;
    background-color: #007bff; /* Bootstrap primary color */
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.login-button:hover {
    background-color: #0056b3; /* Darken the button on hover */
}
